import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import RoutesService from '@/services/whatsapp/routes.service'

export default {
  name: 'WabaPhoneDetail',
  components: { HeaderTopDashboard },
  data () {
    return {
      selectedWaba: null,
      wabaPhones: [],
    }
  },
  computed: {
    wabaId () {
      return this.selectedWaba?.waba_id
    },
    headers () {
      return [
        {
          text: this.$t('Teléfono'),
          align: 'start',
          value: 'phone',
        },
        {
          text: this.$t('Id Teléfono'),
          value: 'phone_id',
          sortable: false,
        },
        {
          text: this.$t('Nombre'),
          value: 'display_name',
          sortable: false,
        },
        {
          text: this.$t('Validación'),
          value: 'route_status',
          sortable: false,
        },
        {
          text: this.$t('Estado'),
          value: 'route_waba_status',
          sortable: false,
        },
        {
          text: this.$t('Calidad'),
          value: 'route_qualities',
          sortable: false,
        },
        {
          text: this.$t('Límite mensajes'),
          value: 'route_level',
          sortable: false,
        },
        {
          text: this.$t('Configurar'),
          value: 'actions',
          sortable: false,
        },
      ]
    },
  },
  created () {
  },
  mounted () {
    RoutesService.getAllFacebookManagerId().then((fbManagerIds) => {
      const facebookBusinessManagerId = this.$route.query.fb_manager_id ?? fbManagerIds[0].facebook_business_manager_id
      RoutesService.getByFacebookManagerId({ facebookBusinessManagerId }).then((wabas) => {
        const wabaId = this.$route.query.waba_id ?? wabas[0].waba_id
        this.getWabaById(wabaId)
      })
    })
  },
  methods: {

    getWabaById (wabaId) {
      const params = {
        wabaId: wabaId,
      }
      RoutesService.getWabaById(params).then((response) => {
        this.selectedWaba = response
        this.getPhones()
      })
    },

    getPhones () {
      const params = {
        wabaId: this.selectedWaba.waba_id,
      }
      RoutesService.getPhonesByWabaId(params).then((response) => {
        this.wabaPhones = response
        this.selectedWaba = this.wabaPhones[0]
      })
    },
  },
}
